import { useSelector, useDispatch } from "react-redux";
import { setUser, setNav, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignOut } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);
  const { pages } = useSelector((state) => state.auth.user);

  const signIn = async ({ email, password }) => {
    try {
      //const resp = await apiSignIn({ email, password })
      let resp = { data: { results: {} } };
      // const resp = await apiSignIn({ email, password })
      resp.data.results = {
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwicm9sZV9pZCI6MSwiaWF0IjoxNjg0MzE2NzY2LCJleHAiOjE2ODQ0MDMxNjZ9.2kaEM6hl9FHZAn6BkZOcwbY3s5z8qWLeM4XNwDk-xig",
        user: [
          {
            id: 1,
            email: "admin@gmail.com",
            phone_number: "1234567890",
            role_id: 1,
            username: "Super Admin",
            avatar: "/img/avatars/thumb-1.jpg",
            authority: ["user"],
            pages: [
              "home",
              "market",
              "productManagement-list",
              "marketmanager-list",
              "addnewuser-list",
              "marketmembers-list",
              "homeScreen",
              "marketsScreen",
              "productsScreen",
              "managersScreen",
              "membersScreen",
              "communication",
            ],
            cta: {
              home: {
                cta_id: [1, 0, 0],
              },
              market: {
                cta_id: [1, 2, 3, 4, 5, 6, 9, 10],
              },
              "productManagement-list": {
                cta_id: [1, 2, 3, 7, 9, 10, 15],
              },
              "marketmanager-list": {
                cta_id: [1, 2, 3, 7, 8, 9, 10, 15],
              },
              "addnewuser-list": {
                cta_id: [1, 2, 3, 9, 10],
              },
              roleaccess: {
                cta_id: [],
              },
              "marketmembers-list": {
                cta_id: [1, 2, 3, 7, 8, 9, 10, 15],
              },
              homeScreen: {
                cta_id: [11, 12, 13, 14],
              },
              marketsScreen: {
                cta_id: [2, 1, 4, 5, 6, 3],
              },
              productsScreen: {
                cta_id: [1, 2, 3, 7],
              },
              managersScreen: {
                cta_id: [1, 2, 3, 7, 8],
              },
              membersScreen: {
                cta_id: [1, 2, 3, 7, 8],
              },
              communication: {
                cta_id: [1, 2],
              },
              subscriptionScreen: {
                cta_id: [],
              },
            },
          },
        ],
      };

      if (resp.data.results) {
        const { token } = resp.data.results;
        dispatch(onSignInSuccess({ token: resp.data.results.token }));
        if (resp.data.results.user) {
          dispatch(setUser(resp.data.results.user[0]));
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    try {
      await apiSignOut();
      handleSignOut();
    } catch (errors) {
      handleSignOut();
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
