export default function acronym(name = "") {
  const shortName = name.match(/\b(\w)/g);

  if (shortName) {
    return shortName.join("");
  }

  return name;
}

export function getInitialsFromName(name) {
  try{
    const nameArray = name.split(" ").slice(0, 2);
    const initials = nameArray.map((name) => name[0].toUpperCase());
    return initials.join("");
  }catch(e){
    return "T";
  }
  
}
