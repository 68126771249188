
import { Card } from "components/ui"
import { Input, Checkbox, FormItem, FormContainer, Select, Button } from 'components/ui'
import { Field, Form, Formik } from 'formik'
import { addMetaData } from "services/Information";
import { toast, Notification } from "components/ui";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { setLayout } from "store/theme/themeSlice";
import { Upload } from "components/ui";
import * as Yup from 'yup'
import { Radio } from "components/ui";
import { addRbtProfileData } from "services/Information";
import { cloneDeep } from "lodash";
import { useState } from "react";
import { AdaptableCard } from "components/shared";
import { addCandidate } from "services/TalentBoard";

export default function RbtTechnician() {

    const options = [{
        value: '210da5f0-6347-4582-a961-57cc2078be31', label: 'Clinic XYZ'
    }];




    const genderOptions = [{
        value: 'Male', label: 'Male',
        value: 'Female', label: 'Female',
        value: 'Others', label: 'Others',
    }];
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const profileStatus = useSelector((state) => state.auth.user.isProfileComplete);


    const validationSchema = Yup.object().shape({
        address: Yup.string()
            .required('Last Name Required'),
        hasCar: Yup.string()
            .required('Last Name Required'),
        therapyMode: Yup.array()
            .min(1, `At least one need's to be selected!`),
        skills: Yup.array()
            .min(1, `At least one need's to be selected!`),



    })


    useEffect(() => {
        dispatch(setLayout('blank'));
        // if (profileStatus) {
        //     navigate('/talentBoard')
        // }


        return (() => {
            dispatch(setLayout('modern'));
        })
    }, [])

    const onSetFormFile = async (form, field, file) => {

        form.setFieldValue(field.name, file[0]);

    }



    return (
        <div className=" max-h-full overflow-y-auto">
            <div className="grid grid-cols-2 h-full gap-16 m-8 ">
                <div className="h-full">
                    <img className="object-cover w-full h-full rounded-lg" src='/img/others/kids.png'></img>
                </div>
                <div>
                    <div className="flex justify-start items-center">
                        <div className="w-10/12">
                            <h4 className="font-extrabold text-green-dark mr-2">Background, Specialities and Prefrences</h4>

                            <div className="mt-8">
                                <Formik
                                    initialValues={{
                                        therapyMode: [],
                                        languages: [],
                                        skills: [],
                                        certifications: [],
                                        gender: '',
                                        ageGroup: '',
                                        address: '',
                                        experience: '',
                                        hasCar: '',
                                        photoUrl: '',

                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={async (values, { resetForm, setSubmitting }) => {
                                        setSubmitting(true);

                                        const formData = new FormData();
                                        formData.append('therapyMode', values.therapyMode);

                                        for (var i = 0; i < values.languages.length; i++) {
                                            formData.append('languages[]', values.languages[i]);
                                        }

                                        for (let i = 0; i < values.skills.length; i++) {
                                            formData.append('skills[]', values.skills[i]);
                                        }

                                        for (let i = 0; i < values.certifications.length; i++) {
                                            formData.append('certifications[]', values.certifications[i]);
                                        }

                                        formData.append('gender', values.gender);
                                        formData.append('address', values.address);
                                        formData.append('hascar', values.hasCar === 'Yes' ? true : false);
                                        formData.append('experience', values.experience);
                                        formData.append('photo', values.photoUrl);
                                        try {
                                            const success = await addRbtProfileData(formData);
                                            setSubmitting(false);
                                            if (success.status === 200) {

                                                navigate('/profile')
                                                toast.push(
                                                    <Notification
                                                        title={"Successfully updated"}
                                                        type="success"
                                                        duration={2500}
                                                    >
                                                        Success.
                                                    </Notification>,
                                                    {
                                                        placement: "top-center",
                                                    }
                                                );
                                            }
                                        } catch (error) {
                                            setSubmitting(false);
                                            toast.push(
                                                <Notification title="Failed to add candidate." type="warning" duration={7000}>
                                                    Failed
                                                </Notification>,
                                                {
                                                    placement: "top-center",
                                                }
                                            );
                                        }




                                    }}
                                >
                                    {({ values, touched, errors, resetForm, setSubmitting, isSubmitting, setFieldValue }) => (
                                        <Form>
                                            <FormContainer>

                                                <FormItem
                                                    label="Therapy mode"
                                                    className='[&>*]:font-light text-green-dark mr-2'
                                                    asterisk
                                                    invalid={Boolean(
                                                        errors.therapyMode &&
                                                        touched.therapyMode
                                                    )}
                                                    errorMessage={errors.therapyMode}
                                                >
                                                    <Field name="therapyMode">
                                                        {({ field, form }) => (
                                                            <>
                                                                <Checkbox.Group
                                                                    onChange={(options) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            options
                                                                        )
                                                                    }
                                                                    value={values.therapyMode}
                                                                >
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="CLINIC"
                                                                    >
                                                                        Clinic{' '}
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="HOME"
                                                                    >
                                                                        Home{' '}
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="SCHOOL"
                                                                    >
                                                                        School{' '}
                                                                    </Checkbox>
                                                                </Checkbox.Group>
                                                            </>
                                                        )}
                                                    </Field>
                                                </FormItem>

                                                <FormItem
                                                    label="Languages"
                                                    asterisk
                                                    className='[&>*]:font-light text-green-dark mr-2'
                                                    invalid={Boolean(
                                                        errors.languages &&
                                                        touched.languages
                                                    )}
                                                    errorMessage={errors.languages}
                                                >
                                                    <Field name="languages">
                                                        {({ field, form }) => (
                                                            <>
                                                                <Checkbox.Group
                                                                    onChange={(options) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            options
                                                                        )
                                                                    }
                                                                    value={values.languages}
                                                                >
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="English"
                                                                    >
                                                                        English{' '}
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="Spanish"
                                                                    >
                                                                        Spanish{' '}
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="French"
                                                                    >
                                                                        French{' '}
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="German"
                                                                    >
                                                                        German{' '}
                                                                    </Checkbox>
                                                                </Checkbox.Group>
                                                            </>
                                                        )}
                                                    </Field>
                                                </FormItem>

                                                <FormItem
                                                    label="Skills"
                                                    className='[&>*]:font-light text-green-dark mr-2'
                                                    asterisk
                                                    invalid={Boolean(
                                                        errors.skills &&
                                                        touched.skills
                                                    )}
                                                    errorMessage={errors.skills}
                                                >
                                                    <Field name="skills">
                                                        {({ field, form }) => (
                                                            <>
                                                                <Checkbox.Group
                                                                    onChange={(options) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            options
                                                                        )
                                                                    }
                                                                    value={values.skills}
                                                                >
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="CPR"
                                                                    >
                                                                        CPR{' '}
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="First-Aid"
                                                                    >
                                                                        First-Aid{' '}
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="Special Needs"
                                                                    >
                                                                        Special Needs{' '}
                                                                    </Checkbox>

                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="Registered Behavior Technician"
                                                                    >
                                                                        Registered Behavior Technician{' '}
                                                                    </Checkbox>
                                                                </Checkbox.Group>
                                                            </>
                                                        )}
                                                    </Field>
                                                </FormItem>

                                                <FormItem
                                                    label="Certifications"
                                                    className='[&>*]:font-light text-green-dark mr-2'
                                                    invalid={Boolean(
                                                        errors.certifications &&
                                                        touched.certifications
                                                    )}
                                                    errorMessage={errors.certifications}
                                                >
                                                    <Field name="certifications">
                                                        {({ field, form }) => (
                                                            <>
                                                                <Checkbox.Group
                                                                    onChange={(options) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            options
                                                                        )
                                                                    }
                                                                    value={values.certifications}
                                                                >
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="Option 1"
                                                                    >
                                                                        Option 1{' '}
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="Option 2"
                                                                    >
                                                                        Option 2{' '}
                                                                    </Checkbox>
                                                                    <Checkbox
                                                                        name={field.name}
                                                                        value="Option 3"
                                                                    >
                                                                        Option 3{' '}
                                                                    </Checkbox>
                                                                </Checkbox.Group>
                                                            </>
                                                        )}
                                                    </Field>
                                                </FormItem>

                                                <FormItem
                                                    label={`Gender `}
                                                    className='[&>*]:font-light text-green-dark mr-2'
                                                    invalid={errors.gender && touched.gender}
                                                    errorMessage={errors.gender}
                                                >
                                                    <Field name="gender">
                                                        {({ field, form }) => (
                                                            <Radio.Group
                                                                value={values.gender}
                                                                onChange={(val) =>
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        val
                                                                    )
                                                                }
                                                            >
                                                                <Radio value={'Male'}>Male</Radio>
                                                                <Radio value={'Female'}>Female</Radio>
                                                                <Radio value={'Other'}>Other</Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </Field>
                                                </FormItem>

                                                <FormItem
                                                    label={`Address`}
                                                    className='[&>*]:font-light text-green-dark mr-2'
                                                    invalid={errors.address && touched.address}
                                                    errorMessage={errors.address}
                                                    asterisk
                                                >
                                                    <Field
                                                        type="text"
                                                        autoComplete="off"
                                                        name="address"
                                                        size='xs'
                                                        placeholder=""
                                                        component={Input}
                                                    />
                                                </FormItem>

                                                <FormItem
                                                    label={`Experience `}
                                                    className='[&>*]:font-light text-green-dark mr-2'
                                                    invalid={errors.experience && touched.experience}
                                                    errorMessage={errors.experience}
                                                >
                                                    <Field
                                                        type="text"
                                                        autoComplete="off"
                                                        name="experience"
                                                        size='xs'
                                                        placeholder=""
                                                        component={Input}
                                                        onChange={(e) => {
                                                            if (isNaN(e.target.value))
                                                                setFieldValue('experience', values.experience);
                                                            else
                                                                setFieldValue('experience', e.target.value);

                                                        }}
                                                    />
                                                </FormItem>

                                                <FormItem
                                                    label="Has Car"
                                                    className='[&>*]:font-light text-green-dark mr-2'
                                                    asterisk
                                                    invalid={Boolean(
                                                        errors.hasCar &&
                                                        touched.hasCar
                                                    )}
                                                    errorMessage={errors.hasCar}
                                                >
                                                    <Field name="hasCar">
                                                        {({ field, form }) => (
                                                            <Radio.Group
                                                                value={values.hasCar}
                                                                onChange={(val) =>
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        val
                                                                    )
                                                                }
                                                            >
                                                                <Radio value={'Yes'}>Yes</Radio>
                                                                <Radio value={'No'}>No</Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </Field>
                                                </FormItem>

                                                <FormItem
                                                    label="Profile Picture"
                                                    invalid={errors.photoUrl && touched.photoUrl}
                                                    errorMessage={errors.photoUrl}
                                                >
                                                    <Field name='photoUrl'>
                                                        {({ field, form }) => {
                                                            return (
                                                                <Upload
                                                                    // beforeUpload={beforeUpload}
                                                                    onChange={files => onSetFormFile(form, field, files)}
                                                                    onFileRemove={files => onSetFormFile(form, field, files)}
                                                                    showList={true}
                                                                    uploadLimit={1}
                                                                    draggable
                                                                />
                                                            )
                                                        }}
                                                    </Field>
                                                </FormItem>

                                                <Button variant="solid" type="submit" color='green-dark' loading={isSubmitting} block>
                                                    Continue
                                                </Button>

                                            </FormContainer>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div>

        // <div className="grid grid-cols-2 h-full gap-4 m-4 mb-10">
        //     <div className="h-full">
        //         <img className="object-fill h-full" src='/img/others/kids.png'></img>
        //     </div>
        //     <div>
        //         <div className="  h-full">
        //             <Card className='flex justify-center items-center'>
        //                 <h4 className="font-extrabold text-green-dark mr-2">Background, Specialities and Prefrences</h4>

        //                 <div className="mt-8">
        //                     <Formik
        //                         initialValues={{
        //                             therapyMode: [],
        //                             languages: [],
        //                             skills: [],
        //                             certifications: [],
        //                             gender: '',
        //                             ageGroup: '',
        //                             address: '',
        //                             experience: '',
        //                             hasCar: '',
        //                             photoUrl: '',

        //                         }}
        //                         validationSchema={validationSchema}
        //                         onSubmit={async (values, { resetForm, setSubmitting }) => {
        //                             setSubmitting(true);

        //                             const formData = new FormData();
        //                             formData.append('therapyMode', values.therapyMode);

        //                             for (var i = 0; i < values.languages.length; i++) {
        //                                 formData.append('languages[]', values.languages[i]);
        //                             }

        //                             for (let i = 0; i < values.skills.length; i++) {
        //                                 formData.append('skills[]', values.skills[i]);
        //                             }

        //                             for (let i = 0; i < values.certifications.length; i++) {
        //                                 formData.append('certifications[]', values.certifications[i]);
        //                             }

        //                             formData.append('gender', values.gender);
        //                             formData.append('address', values.address);
        //                             formData.append('hascar', values.hasCar === 'Yes' ? true : false);
        //                             formData.append('experience', values.experience);
        //                             formData.append('photo', values.photoUrl);
        //                             try {
        //                                 const success = await addRbtProfileData(formData);
        //                                 setSubmitting(false);
        //                                 if (success.status === 200) {
        //                                     navigate('/talentBoard')
        //                                     toast.push(
        //                                         <Notification
        //                                             title={"Successfully updated"}
        //                                             type="success"
        //                                             duration={2500}
        //                                         >
        //                                             Success.
        //                                         </Notification>,
        //                                         {
        //                                             placement: "top-center",
        //                                         }
        //                                     );
        //                                 }
        //                             } catch (error) {
        //                                 setSubmitting(false);
        //                                 toast.push(
        //                                     <Notification title="Failed to add candidate." type="warning" duration={7000}>
        //                                         Failed
        //                                     </Notification>,
        //                                     {
        //                                         placement: "top-center",
        //                                     }
        //                                 );
        //                             }




        //                         }}
        //                     >
        //                         {({ values, touched, errors, resetForm, setSubmitting, isSubmitting, setFieldValue }) => (
        //                             <Form>
        //                                 <FormContainer>

        //                                     <FormItem
        //                                         label="Therapy mode"
        //                                         className='[&>*]:font-light text-green-dark mr-2'
        //                                         asterisk
        //                                         invalid={Boolean(
        //                                             errors.therapyMode &&
        //                                             touched.therapyMode
        //                                         )}
        //                                         errorMessage={errors.therapyMode}
        //                                     >
        //                                         <Field name="therapyMode">
        //                                             {({ field, form }) => (
        //                                                 <>
        //                                                     <Checkbox.Group
        //                                                         onChange={(options) =>
        //                                                             form.setFieldValue(
        //                                                                 field.name,
        //                                                                 options
        //                                                             )
        //                                                         }
        //                                                         value={values.therapyMode}
        //                                                     >
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="CLINIC"
        //                                                         >
        //                                                             Clinic{' '}
        //                                                         </Checkbox>
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="HOME"
        //                                                         >
        //                                                             Home{' '}
        //                                                         </Checkbox>
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="SCHOOL"
        //                                                         >
        //                                                             School{' '}
        //                                                         </Checkbox>
        //                                                     </Checkbox.Group>
        //                                                 </>
        //                                             )}
        //                                         </Field>
        //                                     </FormItem>

        //                                     <FormItem
        //                                         label="Languages"
        //                                         asterisk
        //                                         className='[&>*]:font-light text-green-dark mr-2'
        //                                         invalid={Boolean(
        //                                             errors.languages &&
        //                                             touched.languages
        //                                         )}
        //                                         errorMessage={errors.languages}
        //                                     >
        //                                         <Field name="languages">
        //                                             {({ field, form }) => (
        //                                                 <>
        //                                                     <Checkbox.Group
        //                                                         onChange={(options) =>
        //                                                             form.setFieldValue(
        //                                                                 field.name,
        //                                                                 options
        //                                                             )
        //                                                         }
        //                                                         value={values.languages}
        //                                                     >
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="English"
        //                                                         >
        //                                                             English{' '}
        //                                                         </Checkbox>
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="Spanish"
        //                                                         >
        //                                                             Spanish{' '}
        //                                                         </Checkbox>
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="French"
        //                                                         >
        //                                                             French{' '}
        //                                                         </Checkbox>
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="German"
        //                                                         >
        //                                                             German{' '}
        //                                                         </Checkbox>
        //                                                     </Checkbox.Group>
        //                                                 </>
        //                                             )}
        //                                         </Field>
        //                                     </FormItem>

        //                                     <FormItem
        //                                         label="Skills"
        //                                         className='[&>*]:font-light text-green-dark mr-2'
        //                                         asterisk
        //                                         invalid={Boolean(
        //                                             errors.skills &&
        //                                             touched.skills
        //                                         )}
        //                                         errorMessage={errors.skills}
        //                                     >
        //                                         <Field name="skills">
        //                                             {({ field, form }) => (
        //                                                 <>
        //                                                     <Checkbox.Group
        //                                                         onChange={(options) =>
        //                                                             form.setFieldValue(
        //                                                                 field.name,
        //                                                                 options
        //                                                             )
        //                                                         }
        //                                                         value={values.skills}
        //                                                     >
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="CPR"
        //                                                         >
        //                                                             CPR{' '}
        //                                                         </Checkbox>
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="First-Aid"
        //                                                         >
        //                                                             First-Aid{' '}
        //                                                         </Checkbox>
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="Special Needs"
        //                                                         >
        //                                                             Special Needs{' '}
        //                                                         </Checkbox>

        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="Registered Behavior Technician"
        //                                                         >
        //                                                             Registered Behavior Technician{' '}
        //                                                         </Checkbox>
        //                                                     </Checkbox.Group>
        //                                                 </>
        //                                             )}
        //                                         </Field>
        //                                     </FormItem>

        //                                     <FormItem
        //                                         label="Certifications"
        //                                         className='[&>*]:font-light text-green-dark mr-2'
        //                                         invalid={Boolean(
        //                                             errors.certifications &&
        //                                             touched.certifications
        //                                         )}
        //                                         errorMessage={errors.certifications}
        //                                     >
        //                                         <Field name="certifications">
        //                                             {({ field, form }) => (
        //                                                 <>
        //                                                     <Checkbox.Group
        //                                                         onChange={(options) =>
        //                                                             form.setFieldValue(
        //                                                                 field.name,
        //                                                                 options
        //                                                             )
        //                                                         }
        //                                                         value={values.certifications}
        //                                                     >
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="Option 1"
        //                                                         >
        //                                                             Option 1{' '}
        //                                                         </Checkbox>
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="Option 2"
        //                                                         >
        //                                                             Option 2{' '}
        //                                                         </Checkbox>
        //                                                         <Checkbox
        //                                                             name={field.name}
        //                                                             value="Option 3"
        //                                                         >
        //                                                             Option 3{' '}
        //                                                         </Checkbox>
        //                                                     </Checkbox.Group>
        //                                                 </>
        //                                             )}
        //                                         </Field>
        //                                     </FormItem>

        //                                     <FormItem
        //                                         label={`Gender `}
        //                                         className='[&>*]:font-light text-green-dark mr-2'
        //                                         invalid={errors.gender && touched.gender}
        //                                         errorMessage={errors.gender}
        //                                     >
        //                                         <Field name="gender">
        //                                             {({ field, form }) => (
        //                                                 <Radio.Group
        //                                                     value={values.gender}
        //                                                     onChange={(val) =>
        //                                                         form.setFieldValue(
        //                                                             field.name,
        //                                                             val
        //                                                         )
        //                                                     }
        //                                                 >
        //                                                     <Radio value={'Male'}>Male</Radio>
        //                                                     <Radio value={'Female'}>Female</Radio>
        //                                                     <Radio value={'Other'}>Other</Radio>
        //                                                 </Radio.Group>
        //                                             )}
        //                                         </Field>
        //                                     </FormItem>

        //                                     <FormItem
        //                                         label={`Address`}
        //                                         className='[&>*]:font-light text-green-dark mr-2'
        //                                         invalid={errors.address && touched.address}
        //                                         errorMessage={errors.address}
        //                                         asterisk
        //                                     >
        //                                         <Field
        //                                             type="text"
        //                                             autoComplete="off"
        //                                             name="address"
        //                                             size='xs'
        //                                             placeholder=""
        //                                             component={Input}
        //                                         />
        //                                     </FormItem>

        //                                     <FormItem
        //                                         label={`Experience `}
        //                                         className='[&>*]:font-light text-green-dark mr-2'
        //                                         invalid={errors.experience && touched.experience}
        //                                         errorMessage={errors.experience}
        //                                     >
        //                                         <Field
        //                                             type="text"
        //                                             autoComplete="off"
        //                                             name="experience"
        //                                             size='xs'
        //                                             placeholder=""
        //                                             component={Input}
        //                                             onChange={(e) => {
        //                                                 if (isNaN(e.target.value))
        //                                                     setFieldValue('experience', values.experience);
        //                                                 else
        //                                                     setFieldValue('experience', e.target.value);

        //                                             }}
        //                                         />
        //                                     </FormItem>

        //                                     <FormItem
        //                                         label="Has Car"
        //                                         className='[&>*]:font-light text-green-dark mr-2'
        //                                         asterisk
        //                                         invalid={Boolean(
        //                                             errors.hasCar &&
        //                                             touched.hasCar
        //                                         )}
        //                                         errorMessage={errors.hasCar}
        //                                     >
        //                                         <Field name="hasCar">
        //                                             {({ field, form }) => (
        //                                                 <Radio.Group
        //                                                     value={values.hasCar}
        //                                                     onChange={(val) =>
        //                                                         form.setFieldValue(
        //                                                             field.name,
        //                                                             val
        //                                                         )
        //                                                     }
        //                                                 >
        //                                                     <Radio value={'Yes'}>Yes</Radio>
        //                                                     <Radio value={'No'}>No</Radio>
        //                                                 </Radio.Group>
        //                                             )}
        //                                         </Field>
        //                                     </FormItem>

        //                                     <FormItem
        //                                         label="Profile Picture"
        //                                         invalid={errors.photoUrl && touched.photoUrl}
        //                                         errorMessage={errors.photoUrl}
        //                                     >
        //                                         <Field name='photoUrl'>
        //                                             {({ field, form }) => {
        //                                                 return (
        //                                                     <Upload
        //                                                         // beforeUpload={beforeUpload}
        //                                                         onChange={files => onSetFormFile(form, field, files)}
        //                                                         onFileRemove={files => onSetFormFile(form, field, files)}
        //                                                         showList={true}
        //                                                         uploadLimit={1}
        //                                                         draggable
        //                                                     />
        //                                                 )
        //                                             }}
        //                                         </Field>
        //                                     </FormItem>

        //                                     <Button variant="solid" type="submit" color='green-dark' loading={isSubmitting} block>
        //                                         Continue
        //                                     </Button>

        //                                 </FormContainer>
        //                             </Form>
        //                         )}
        //                     </Formik>
        //                 </div>
        //             </Card>
        //         </div>
        //     </div >
        // </div >







    )
}