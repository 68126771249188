import ApiService from "./ApiService";

export async function addCandidate(data) {
  return ApiService.fetchData({
    url: "/candidate",
    method: "post",
    data,
  });
}

export async function getAllCandidates(params) {
  return ApiService.fetchData({
    //page=1&limit=1
    url: "/candidate",
    method: "get",
    params,
  });
}

export async function addCandidateInterview(data) {
  return ApiService.fetchData({
    url: "/candidate/interview",
    method: "post",
    data,
  });
}

export async function getCandidateInterview(params) {
  return ApiService.fetchData({
    url: "/candidate/interview",
    method: "get",
    params,
  });
}

export async function getCandidateByInterviewId(params) {
  return ApiService.fetchData({
    url: "/candidate/byInterviewId",
    method: "get",
    params,
  });
}

export async function getCandidateByID(params) {
  return ApiService.fetchData({
    url: "/candidate/id",
    method: "get",
    params,
  });
}

export async function getBCBACandidates(params) {
  return ApiService.fetchData({
    url: `/candidate/bcba`,
    method: "get",
    params,
  });
}

export async function sendBCBAInterview(params) {
  return ApiService.fetchData({
    url: `/candidate/interview-bcba`,
    method: "post",
    data: params,
  });
}
