import React, { memo, useMemo, lazy, Suspense, useEffect } from "react";
import { Loading } from "components/shared";
import { useDispatch, useSelector } from "react-redux";
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK,
} from "constants/theme.constant";
import useAuth from "utils/hooks/useAuth";
import useDirection from "utils/hooks/useDirection";
import useLocale from "utils/hooks/useLocale";
import { fetchSettings } from "store/base/commonSlice";
import { fetchSendBirdData } from "store/auth/userSlice";
import { SENDBIRD_COLOR_SET, SENDBIRD_APP_ID } from "constants/app.constant";
import appConfig from "configs/app.config";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { useMediaQuery } from "usehooks-ts";
import { toast } from "react-toastify";

const { sendBirdAppId } = appConfig;

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import("./ClassicLayout")),
  [LAYOUT_TYPE_MODERN]: lazy(() => import("./ModernLayout")),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import("./StackedSideLayout")),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import("./SimpleLayout")),
  [LAYOUT_TYPE_DECKED]: lazy(() => import("./DeckedLayout")),
  [LAYOUT_TYPE_BLANK]: lazy(() => import("./BlankLayout")),
};

const Layout = () => {
  const dispatch = useDispatch();
  const layoutType = useSelector((state) => state.theme.layout.type);
  const sendBirdData = useSelector((state) => state.auth.user.sendBirdData);
  const matches = useMediaQuery("(max-width: 768px)");
  const newClientsLoaded = useSelector(
    (s) => s?.fetchOverviewData?.data?.fetchNewClientsLoaded
  );
  const newClientsError = useSelector(
    (s) => s?.fetchOverviewData?.data?.fetchNewClientsError
  );
  const newStaffLoaded = useSelector(
    (s) => s?.fetchOverviewData?.data?.fetchNewStaffLoaded
  );
  const newStaffError = useSelector(
    (s) => s?.fetchOverviewData?.data?.fetchNewStaffError
  );
  const authSession = useSelector((state) => state.auth.session);

  useEffect(() => {
    if (newClientsLoaded) {
      toast.success("New clients loaded successfully");
    }
  }, [newClientsLoaded]);
  useEffect(() => {
    if (newClientsError) {
      toast.error("Failed to load new clients");
    }
  }, [newClientsError]);

  useEffect(() => {
    if (newStaffLoaded) {
      toast.success("New staff loaded successfully");
    }
  }, [newStaffLoaded]);
  useEffect(() => {
    if (newStaffError) {
      toast.error("Failed to load new staff");
    }
  }, [newStaffError]);

  useEffect(() => {
    if (
      !sendBirdData ||
      !sendBirdData.sessionToken ||
      new Date(sendBirdData.expiresAt) < new Date()
    ) {
      console.log("fetching sendbird data");
      dispatch(fetchSendBirdData());
    }
  }, []);

  useEffect(() => {
    if (authSession?.signedIn) {
      dispatch(fetchSettings());
    }
  }, [authSession]);

  const { authenticated } = useAuth();

  useDirection();

  useLocale();

  const AppLayout = useMemo(() => {
    if (authenticated) {
      return layouts[layoutType];
    }
    return lazy(() => import("./AuthLayout"));
  }, [layoutType, authenticated]);

  return (
    <Suspense
      fallback={
        <div className="flex flex-auto flex-col h-[100vh]">
          <Loading loading={true} />
        </div>
      }
    >
      <SendbirdProvider
        appId={sendBirdAppId}
        userId={sendBirdData?.user}
        accessToken={sendBirdData?.sessionToken}
        colorSet={SENDBIRD_COLOR_SET}
        theme="light"
        mediaQueryBreakPoint={matches}
      >
        <AppLayout />
      </SendbirdProvider>
    </Suspense>
  );
};

export default memo(Layout);
