export const APP_NAME = "TheraAdmin";
export const PERSIST_STORE_NAME = "admin";
export const REDIRECT_URL_KEY = "redirectUrl";
export const OTP_LENGTH = 6;
// export const SENDBIRD_APP_ID = "99280956-14C9-4106-98B7-224528335A21";  //dev
export const SENDBIRD_APP_ID = "57681BDF-C5DC-4B49-BB42-AF862670347D"; //prod
export const SENDBIRD_COLOR_SET = {
  "--sendbird-light-primary-500": "#182794",
  "--sendbird-light-primary-400": "#1F33C5",
  "--sendbird-light-primary-300": "#3C50E0",
  "--sendbird-light-primary-200": "#5F70E6",
  "--sendbird-light-primary-100": "#828FEB44",
};
export const CLIENT_SHEET_HEADERS = [
  "Child Name",
  "Status",
  "Progress",
  "Scheduled Hours",
  "Authorized Hours",
  "Staff",
  "Address",
  "Zip Code",
];

export const STAFF_SHEET_HEADERS = [
  "Staff Name",
  "Role",
  "Status",
  "Utilization",
  "Available Hours",
];

export const CLIENT_STATS_HEADERS = [
  "Name",
  "EHR ID",
  "Authorized Hours",
  "Scheduled Hours",
  "Direct Therapy Hours",
  "Billable Hours",
  "Cancellation Hours",
  "Gap Hours",
  "Late Arrivals",
  "Late Pickups",
];

export const CLIENT_CUSTOM_STATUS_HEADERS = ["Name", "Status", "Modified Date"];
export const CLIENT_CUSTOM_STATUS_HISTORY_HEADERS = ["Status", "Modified Date"];

export const STAFF_STATS_HEADERS = [
  "Name",
  "Utilization",
  "Available Hours",
  "Scheduled Hours",
  "Billable Hours",
  "Direct Therapy Hours",
  "Non-Billable Hours",
  "Late Arrivals",
  "Cancellation Hours",
  "Cancellation Days",
];

export const STAFF_VIDEO_STATS_HEADERS = [
  "Course Start Date",
  "Learner's Name",
  "Course Title",
  "No. of Mins",
];
