import ApiService from "./ApiService";

export async function getChannels(params) {
  return ApiService.fetchData({
    url: "/chat/channel/list",
    method: "get",
    params,
  });
}

export async function upsertChannel(data) {
  return ApiService.fetchData({
    url: "/chat/channel/upsert",
    method: "post",
    data,
  });
}

export async function getSendBirdData() {
  return ApiService.fetchData({
    url: `/chat/user/token`,
    method: "get",
  });
}
