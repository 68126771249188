const appConfig = {
  // apiPrefix:  process.env.REACT_APP_BASE_URL || 'http://_/api',
  // apiPrefix: 'http://localhost:8282/v1',
  // sendBirdAppId: "99280956-14C9-4106-98B7-224528335A21", //dev
  // apiPrefix: "https://dev-dot-theradriver-prod.uc.r.appspot.com/v1",
  apiPrefix: "https://api.theradriver.com/v1",
  sendBirdAppId: "57681BDF-C5DC-4B49-BB42-AF862670347D",  // prod
  authenticatedEntryPath: "/router",
  unAuthenticatedEntryPath: "/home",
  loginEntryPath: "/sign-in",
  tourPath: "",
  enableMock: false,
};

export default appConfig;

//https://dev-dot-theradriver-prod.uc.r.appspot.com/v1/treatment-plan/client_status
// auth token
