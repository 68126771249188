import ApiService from "./ApiService";

export async function getTraingVideos(params) {
  return ApiService.fetchData({
    url: "/video",
    method: "get",
    params,
  });
}

export async function getTraingVideosById(params) {
  return ApiService.fetchData({
    url: "/video/id",
    method: "get",
    params,
  });
}

export async function getQuestionsByCourse(params) {
  return ApiService.fetchData({
    url: "/course/test",
    method: "get",
    params,
  });
}

export async function submitCertificationTest(data) {
  return ApiService.fetchData({
    url: "/course/test/submit",
    method: "post",
    data,
  });
}

export async function downloadCertificate(data) {
  return ApiService.fetchData({
    url: "/course/test/certificate",
    method: "post",
    data,
  });
}

export async function quizProgressTracking(data) {
  return ApiService.fetchData({
    url: "/video/progress",
    method: "post",
    data,
  });
}

export async function rbtCourse(params) {
  return ApiService.fetchData({
    url: "/video/course",
    method: "get",
    params,
  });
}

export async function allVideos(params) {
  // remove clinic id
  // params.clinic="210da5f0-6347-4582-a961-57cc2078be31";
  return ApiService.fetchData({
    url: "/video",
    method: "get",
    params,
  });
}

export async function trainingGuide(params) {
  return ApiService.fetchData({
    //url: '/course/training/progress',
    url: "/course/training/guide",

    method: "get",
    params,
  });
}

export async function getTechProg(params) {
  return ApiService.fetchData({
    url: "/course/training/progress",
    method: "get",
    params,
  });
}

export async function submitTrainingGuide(data) {
  return ApiService.fetchData({
    url: "/course/training/guide-response/bulk",
    method: "post",
    data,
  });
}

export async function fetchVideoAttempts(params) {
  return ApiService.fetchData({
    url: "/course/training/competence-review",
    method: "get",
    params,
  });
}

export async function fetchTechProgress(params) {
  return ApiService.fetchData({
    url: "/course/training/progress/all",
    method: "get",
    params,
  });
}

export async function fetchVidoSequence(params) {
  return ApiService.fetchData({
    url: "/video/course/sequence",
    method: "get",
    params,
  });
}

export async function videoUpload(data) {
  return ApiService.fetchData({
    url: "/video",
    method: "post",
    data,
  });
}

export async function fetchAllTrainigVideo(params) {
  return ApiService.fetchData({
    url: "/video/clinic",
    method: "get",
    params,
  });
}

export async function fetchAssignedVideos(params) {
  return ApiService.fetchData({
    url: "/video/recommendations",
    method: "get",
    params,
  });
}

export async function assignVideos(data) {
  return ApiService.fetchData({
    url: "/video/assign",
    method: "post",
    data,
  });
}

export async function fetchTechTestAnalytics(params) {
  return ApiService.fetchData({
    url: "/course/test/analytics",
    method: "get",
    params,
  });
}

export async function fetchVideoAnalytics(params) {
  return ApiService.fetchData({
    url: "/course/video/analytics",
    method: "get",
    params,
  });
}

export async function fetchTechVideosLastWatched(params) {
  return ApiService.fetchData({
    url: "/video/lastWatched",
    method: "get",
    params,
  });
}
