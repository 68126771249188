import ApiService from "./ApiService";

// export async function apiSignIn (data) {
//     return ApiService.fetchData({
//         url: '/user/sign-in',
//         method: 'post',
//         data
//     })
// }

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: "user/login-new",
    method: "post",
    data,
  });
}

export async function register(data) {
  return ApiService.fetchData({
    url: "user/register-new",
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/sign-up",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: "/sign-out",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/user/send-reset-password",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/user/reset-password",
    method: "put",
    data,
  });
}
