import { createSlice } from '@reduxjs/toolkit'
export const sessionSlice = createSlice({
	name: 'auth/session',
	initialState: {
        token: '',
        cta : '',
        signedIn: false,
        idToken:''
    },
	reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload.token
            state.cta = action.payload.cta
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
        },
        setToken: (state, action) =>  {
            state.token = action.payload
        },
        setIdToken: (state, action) =>  {
            state.idToken = action.payload
        },
        setNav: (state, action) =>  {
            state.nav = action.payload
        },
        setCta: (state, action) =>  {
            state.cta = action.payload
        }
	},
})

export const { onSignInSuccess, onSignOutSuccess, setToken, setCta, setIdToken } = sessionSlice.actions

export default sessionSlice.reducer