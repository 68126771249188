import ApiService from "./ApiService";

export async function getAllClinic(params) {
  return ApiService.fetchData({
    url: "/clinic",
    method: "get",
    params,
  });
}

export async function getLocations(params) {
  return ApiService.fetchData({
    url: "/clinic/locations",
    method: "get",
    params,
  });
}

export async function getSettings(params) {
  return ApiService.fetchData({
    url: "/clinic/settings",
    method: "get",
    params,
  });
}

export async function changeLocation(params) {
  return ApiService.fetchData({
    url: "/user/change-location",
    method: "post",
    data: params,
  });
}

export async function upsertClientLocation(params) {
  return ApiService.fetchData({
    url: "/mapping/locations/upsert",
    method: "post",
    data: params,
  });
}

export async function getPodsList(params) {
  return ApiService.fetchData({
    url: "/clients/get-pods",
    method: "get",
    params,
  });
}

export async function createPod(params) {
  return ApiService.fetchData({
    url: "/clients/create-mapping",
    method: "post",
    data: params,
  });
}
