import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useMediaQuery } from "usehooks-ts";

const customStyle = {
  visibility: {
    desktop: {
      position: "bl", // 'br', 'bl', 'cr', 'cl', 'tr', 'tl'
      yOffset: 75
    },
    mobile: {
      position: "bl", // 'br', 'bl', 'cr', 'cl', 'tr', 'tl'
      yOffset: 75
    },
  },
};

const TawkButton = () => {
  const matches = useMediaQuery("(max-width: 768px)");
  if (matches) return null;
  return (
    <TawkMessengerReact
      propertyId="6607db601ec1082f04dcf537"
      widgetId="1hq7b1t86"
      customStyle={customStyle}
    />
  );
};

export default TawkButton;
